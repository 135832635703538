import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { PolicyList } from '@components/Policies/PolicyList';
import * as contentful from 'types/contentful';
import { MainMenu } from '@components/MainMenu';
import SortBar from '@components/SortBar';
import { PolicyCardsSearch } from '@components/Policies/PolicyCardsSearch';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import { SuppliersCaveat } from '@components/SuppliersCaveat';
import { InlineContainer } from '@components/InlineContainer';
import { Item } from '@components/SortBar/types';
import { IPageProps } from '../types';

const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'carRentalName',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

const PolicyCarRentalsPage = (props: IPageProps<contentful.CarRental[]>): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname },
  } = props;
  const [filterData, setFilterData] = useState<contentful.CarRental[]>([]);
  const [emptyItemsMessageVisible, setEmptyItemsMessageVisibile] = useState(false);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortByItem[0]);

  const displayFilterData = (data: contentful.CarRental[]): void => {
    setEmptyItemsMessageVisibile(false);
    setFilterData(data);
  };

  const handleSortSelect = (data: contentful.CarRental[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setFilterData(data);
  };

  const handleSearchChange = (data: contentful.CarRental[], isResetData: boolean): void => {
    if (isResetData) {
      displayFilterData(pageContext.data);

      return;
    }

    if (isEmpty(data)) {
      setEmptyItemsMessageVisibile(true);
    } else {
      displayFilterData(data);
    }
  };

  const data = !isEmpty(filterData) ? filterData : pageContext.data;

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <InlineContainer>
        <PolicyCardsSearch
          handleValueChange={handleSearchChange}
          data={pageContext.data}
          searchFields={['carRentalName']}
          filterByFieldName="carRentalName"
        />
        <SortBar
          data={data}
          options={sortByItem}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
        <SuppliersCaveat />
      </InlineContainer>
      {emptyItemsMessageVisible ? (
        <NoCardMatchesFoundMessage />
      ) : (
        <PolicyList
          getItemProps={item => ({
            items: [
              {
                content: item.faceMasks?.json,
                title: 'Face Masks',
                classImg: 'faceMasksImg',
              },
              {
                content: item.healthChecks?.json,
                title: 'Health Checks',
                classImg: 'healthChecksImg',
              },
              {
                content: item.socialDistancing?.json,
                title: 'Social Distancing',
                classImg: 'socialDistancingImg',
              },
              {
                content: item.cleaningPolicy?.json,
                title: 'Cleaning Policy',
                classImg: 'cleaningPolicyImg',
              },
              {
                content: item.pickUpDropOff?.json,
                title: 'Pick-Up / Drop-Off',
                classImg: 'pickUpDropOffImg',
              },
            ],
            title: item.carRentalName,
            updatedAt: item.updatedAt,
            logoUrl: item.logoImage?.file.url,
            link: item.carRentalUrl
              ? { url: item.carRentalUrl.link, label: item.carRentalUrl.label }
              : undefined,
            key: item.id,
          })}
          data={data}
        />
      )}
    </>
  );
};

export default PolicyCarRentalsPage;
